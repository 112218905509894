<script>
import SearchAndActions from "@/components/Table/SearchAndActions.vue";
import Header from "@/components/Table/Header.vue";
import axios from "axios";
import DataTable from "@/components/Table/DataTable.vue";
import ActionsButton from "@/components/Table/ActionsButton.vue";
import TablePagination from "@/components/Table/TablePagination.vue";

export default {
  name: "Consumer",
  components: {TablePagination, ActionsButton, DataTable, Header, SearchAndActions},
  data() {
    return {
      breadcrumbs: [{text: "Home", path: "/dashboard", isActive: false}, {
        text: "Consumers",
        path: "/consumer",
        isActive: true
      }],
      tableData: [],
      pagination: {
        current_page: 1,
        per_page: 10,
        total: 0,
        last_page: 0,
      },
      search: null,
      sort: '-created_at',
      date: null,
    }
  },
  created() {
    this.$store.dispatch("Config/setBreadCrumb", this.breadcrumbs);
  },
  mounted() {
    let page = this.$route.query.page ? this.$route.query.page : 1;
    let per_page = this.$route.query.per_page ? this.$route.query.per_page : 10;
    let search = this.$route.query.search ? this.$route.query.search : null;
    let sort = this.$route.query.sort ? this.$route.query.sort : '-created_at';
    let date = this.$route.query.date ? this.$route.query.date : null;

    this.getListData(page, per_page, search, sort, date);
  },
  methods: {
    getListData(page = 1, per_page = 10, search = null, sort = '-created_at', date = null) {
      let url = '/admin/consumers-list';
      let params = {
        page: page,
        per_page: per_page ? per_page : this.pagination.per_page,
        search: search ? search : this.search,
        sort: sort,
        date: date ? date : this.date,
      };

      axios.get(url, {params})
          .then(response => {
            this.tableData = response.data.data;
            this.pagination = response.data.meta;
            this.sort = sort;

            this.$router.replace({query: params});
          })
    },
    onPageChange(page) {
      this.getListData(page, this.pagination.per_page, this.search, this.sort, this.date);
    },
    onPageSizeChanged(pageSize) {
      this.getListData(1, pageSize, this.search, this.sort, this.date);
    },
    onSearch(search) {
      this.search = search ? search : null;
      this.getListData(1, this.pagination.per_page, this.search, this.sort, this.date);
    },
    sortBy(field) {
      let sort = this.sort === field ? '-' + field : field;
      this.getListData(1, this.pagination.per_page, this.search, sort, this.date);
    },
    onImport() {
      this.$router.push({name: 'ConsumerImport'});
    },
    onDate(date) {
        this.date = date ? date.join(',') : null;
        this.getListData(1, this.pagination.per_page, this.search, this.sort, this.date);
    }
  }
}
</script>

<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <h6 class="text-uppercase font-weight-bold text-dark">Consumers</h6>
      </div>

      <div class="col-md-12">
        <div class="card p-4">
          <SearchAndActions
              @search="onSearch"
              @import="onImport"
              @date="onDate"
              action-url="/admin/consumers-list"
              download-file-name="consumers"
          >
            <el-button
                v-if="checkUsrPermissions(['Consumer_all', 'Consumer_create'])"
                type="primary"
                @click="$router.push({name: 'CreateConsumer'})"
                size="small"
                class="ml-2"
                icon="el-icon-plus"
            >
              Add New
            </el-button>
          </SearchAndActions>

          <div class="row">
            <div class="col-md-12">
              <DataTable>
                <template #header>
                  <Header :sort="false" title="Sl"/>
                  <Header active="account_number" :ordering="sort" @sortBy="sortBy('account_number')"
                          title="Account No"/>
                  <Header active="first_name" :ordering="sort" @sortBy="sortBy('first_name')" title="Consumer"/>
                  <Header active="mobile_number" :ordering="sort" @sortBy="sortBy('mobile_number')" title="Mobile No"/>
                  <Header active="category" :ordering="sort" @sortBy="sortBy('category')" title="Category"/>
                  <Header active="meter" :ordering="sort" @sortBy="sortBy('meter')" title="Meter"/>
                  <Header active="ward_number" :ordering="sort" @sortBy="sortBy('ward_number')" title="Ward No"/>
                  <Header
                      :sort="false"
                      title="Actions"
                      v-if="
                          checkUsrPermissions([
                            'Consumer_all',
                            'Consumer_show',
                            'Consumer_edit',
                            'ConsumerAssign_team',
                          ])
                        "
                  />
                </template>

                <tr
                    v-for="(data, index) in tableData"
                    :key="data.id"
                >
                  <th scope="row">
                    {{ pagination.from + index }}
                  </th>
                  <td>
                    <router-link :to="`/consumers/${data.id}`">{{ data.account_number }}</router-link>
                  </td>
                  <td>
                    {{ data.name }}
                  </td>
                  <td>
                    {{ data.mobile_number }}
                  </td>
                  <td>
                    {{ data.category }}
                  </td>
                  <td>
                    <template v-if="data.meter">
                      Meter No: {{ data.meter.meter_number }}<br>
                      Size: {{ data.meter.connection_size }}<br>
                      Type: {{ data.meter.meter_type }}<br>
                      Status: {{ data.meter.meter_status }}
                    </template>
                  </td>
                  <td>
                    {{ data.ward_number }}
                  </td>
                  <td
                      v-if="
                          checkUsrPermissions([
                            'Consumer_all',
                            'Consumer_show',
                            'Consumer_edit',
                            'ConsumerAssign_team',
                          ])
                        "
                  >
                    <ActionsButton>
                      <a
                          v-if="checkUsrPermissions(['Consumer_all', 'Consumer_show'])"
                          href="#"
                          @click.prevent="$router.push('/consumers/' + data.id)"
                      >
                        Show
                      </a>
                      <a
                          v-if="checkUsrPermissions(['Consumer_all', 'Consumer_edit'])"
                          href="#"
                          @click.prevent="$router.push({ name: 'EditConsumer', params: {id: data.id}})"
                      >
                        Edit
                      </a>
                      <router-link
                          v-if="checkUsrPermissions(['History_all'])"
                          :to="'/consumers/' + data.id + '/history'"
                      >
                        History
                      </router-link>
                    </ActionsButton>
                  </td>
                </tr>
              </DataTable>

              <div class="mt-5" v-if="tableData.length">
                <TablePagination
                    :pagination="pagination"
                    @pagechanged="onPageChange"
                    @pagesizechanged="onPageSizeChanged"
                />
              </div>

              <div v-else>
                <p class="text-center font-weight-bold">
                    No data found
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>